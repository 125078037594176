import React, { useContext } from 'react';
import { GlobalContext } from '@context';
import ScrollMarquee from '@components/ScrollMarquee';

import styles from './404.module.scss';
import Meta from '@components/Meta';

const ErrorPage = () => {
  const { hover } = useContext(GlobalContext);
  return (
    <>
      <Meta
        title="OddCommon | 404"
        description="Looks like this page doesn’t exist. We’re trying to find a replacement for it. Kindly enjoy this 404 page in the mean time!"
      />
      <div className={styles.wrapper}>
        <section>
          <ScrollMarquee
            items={['404', 'Page Not Found', '404', 'Page Not Found', '404', 'Page Not Found']}
            direction="left"
            speed={50}
            hover={hover}
          />
          <ScrollMarquee
            items={['404', 'Page Not Found', '404', 'Page Not Found', '404', 'Page Not Found']}
            direction="right"
            speed={50}
            hover={hover}
          />
          <ScrollMarquee
            items={['404', 'Page Not Found', '404', 'Page Not Found', '404', 'Page Not Found']}
            direction="left"
            speed={50}
            hover={hover}
          />
        </section>
      </div>
    </>
  );
};

export default ErrorPage;
